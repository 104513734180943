import {useEffect, useState} from "react";
import {AnnouncementFeedback, FeedbackStatus} from "../../../types/types";

interface FeedbackProgressProps {
    feedback: AnnouncementFeedback[];
    total: number;
}
const initiateList = (seenProgress:number,acknowledgeProgress:number)=>{
    return [
        {
            id: Math.random(),
            value: acknowledgeProgress,
            color: '#5abf96'
        },
        {
            id: Math.random(),
            value: seenProgress,
            color: '#cfe1ed'
        },
        {
            id: Math.random(),
            value: 100 - (seenProgress + acknowledgeProgress),
            color: '#f2f2f2'
        }
        
    ];


}
export const FeedbackProgress = ({feedback, total}: FeedbackProgressProps) => {
    // const total = feedback.length||1;
    const seenCount = feedback.filter(r => r.status === FeedbackStatus.seen).length || 0;
    const acknowledgeCount = feedback.filter(r => r.status === FeedbackStatus.acknowledge).length || 0;
    const seenProgress = (seenCount / total) * 100;
    const acknowledgeProgress = (acknowledgeCount / total) * 100;

    const itemList = initiateList(seenProgress,acknowledgeProgress);

    const [widths, setWidths] = useState(
        itemList.map(() => {
            return 0;
        })
    );
    useEffect(() => {
        let cancel = false;
        requestAnimationFrame(() => {
            if (cancel) return;
            // Set a new array of percentage widths based on the props
            setWidths(
                itemList.map(item => {
                    return item.value;
                })
            );
        });
        return () => {
            cancel = true;
        }
    }, [itemList]);
    return (
        <>
            {/*<div className="prog-wrapper">*/}
            {/*    <div className="prog-seen" style={{"width": seenProgress + "%"}}/>*/}
            {/*    <div className="prog-acknowledged" style={{"width": acknowledgeProgress + "%"}}/>*/}

            {/*</div>*/}
                <div className="prog-wrapper">
                    <div className="progressVisualFull">
                        {itemList.map((item, index) => {
                            // map each part into separate div and each will be animated
                            // because of the "transition: width 2s;" css in class "progressVisualPart"
                            // and because of the new width ("widths[index]", previous one was 0)
                            return (
                                <div
                                    // There won't be additional changes in the array so the index can be used
                                    key={item.id}
                                    style={{
                                        width: widths[index],
                                        // setting the actual color of bar part
                                        backgroundColor: item.color
                                    }}
                                    className="progressVisualPart"
                                />
                            );
                        })}
                    </div>
                </div>


        </>
    )
}
