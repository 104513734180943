import './App.css';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {Dashboard} from './pages/dashboard/Dashboard';
import {Login} from './pages/login/Login';
import {ForgotPassword} from './pages/forgotPassword/ForogotPassword';
import {ResetPassword} from './pages/resetPassword/ResetPassword';
import {Register} from './pages/register/Register';

const isLoggedIn = () => {
  return localStorage.getItem('token');
}

export const App = () => {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/" exact>
            {isLoggedIn() === null?<Redirect to="/login" />: <Redirect to="/dashboard" />}
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
