import { Button, Card, Col, Form, Image, Input, message, Row, Spin } from "antd";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { register } from "../../services/auth";
import "./style.css";
import backgroud from "../../assets/Softcodeit_Logo-02.png";

export interface RegisterForm {
  name: string;
  email: string;
  password: string;
  token: string;
}

export const Register: Function = () => {
  let [loading, setLoading] = useState(false);
  let { push } = useHistory();
  let { search } = useLocation();
  if (loading) {
    return <Spin size="large" className="spin" />;
  }
  return (
    <Row align="middle" className="full-height">
      <Col span={8} offset={8}>
        <Card className="login-logo">
          <Image src={backgroud} preview={false}/>
        </Card>
        <Card bordered={false}>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={(data: RegisterForm) => {
              setLoading(true);
              register(data)
                .then((respnse: any) => {
                  setLoading(false);
                  if (respnse.error) {
                    message.error(respnse.error);
                    return;
                  } else if (respnse.token) {
                    localStorage.setItem("token", respnse.token);
                    message.success("You are successfully registered!. Nvigating to dashboard ...");
                    push('/dashboard');
                  }
                })
                .catch(() => {
                  setLoading(false);
                  message.error(
                    "Failed to register, please try again ...!"
                  );
                });
            }}
            onFinishFailed={() => {
              message.error("Please type your Softcodeit email!");
            }}
          >
            <Form.Item
              label="Name"
              name="name"
              hasFeedback
              rules={[{ required: true, message: "Please input your name!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              hasFeedback
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your Softcodeit email!",
                },
              ]}
              initialValue={new URLSearchParams(search).get("email")}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              hasFeedback
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 8, message: "Minimum password length is 8!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Sign Up
              </Button>
              <Button
                type="link"
                onClick={() => {
                  push("/login");
                }}
              >
                Back
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
