import { Button, Card, Col, Form, Image, Input, message, Row, Spin } from "antd";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { resetPasswordConfirm } from "../../services/auth";
import "./style.css";
import backgroud from "../../assets/Softcodeit_Logo-02.png";

export interface ResetPasswordForm {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

export const ResetPassword: Function = () => {
  let [loading, setLoading] = useState(false);
  let { push } = useHistory();
  let { search } = useLocation();
  
  if (loading) {
    return <Spin size="large" className="spin" />;
  }
  return (
    <Row align="middle" className="full-height">
      <Col span={24}>
        <Card className="login-logo">
          <Image src={backgroud} preview={false}/>
        </Card>
        <Card  bordered={false}>
          <Form
            name="basic"
            className="resetPWForm"
            layout={"vertical"}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={(data: ResetPasswordForm) => {
              setLoading(true);
              resetPasswordConfirm(data)
                .then((respnse: any) => {
                  setLoading(false);
                  if (respnse.error) {
                    message.error(respnse.error);
                    return;
                  } else if (respnse.message) {
                    message.success(respnse.message);
                  }
                  push('/login');
                })
                .catch(() => {
                  setLoading(false);
                  message.error(
                    "Failed to reset password, please try again ...!"
                  );
                  push('/login');
                });
            }}
            onFinishFailed={() => {
              message.error("Please type your softcodeit email!");
            }}
          >
            {/* <Form.Item
              label="Email"
              name="email"
              hasFeedback
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your registered email!",
                },
              ]}
            >
              <Input disabled/>
            </Form.Item> */}
            <Form.Item
              label="Password"
              name="password"
              hasFeedback
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 8, message: "Minimum password length is 8!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="password_confirmation"
              hasFeedback
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 8, message: "Minimum password length is 8!" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <div className="hiddenFields">
              <Form.Item name="token" initialValue={new URLSearchParams(search).get('token')}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item name="email" initialValue={new URLSearchParams(search).get('email')}>
                <Input type="hidden" />
              </Form.Item>
            </div>
            
            <Row>
              <Col span={11}>
              <Button
                type="default"
                onClick={() => {
                  push("/login");
                }}
              >
                Back
              </Button>
              </Col>
              <Col span={11} offset={2}>
                <Button type="primary" htmlType="submit">
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
