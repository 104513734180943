import React from "react";
import { Image, message } from "antd";
import { useEffect, useState } from "react";
import { me } from "../../services/auth";
import { Layout, Menu } from "antd";
import {
  SoundOutlined,
  TeamOutlined,
  DashboardOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import "./style.css";
import backgroud from "../../assets/logo@2x.png";
import { User } from "../../types/types";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Announcements } from "../announcements/Announcements";
import { Summery } from "../summery/Summery";
import { Employees } from "../employees/Employees";
import { Events } from "../events/Events";
import { url } from "inspector";

const { Header, Content, Sider } = Layout;

const emptyUser: User = {
  name: "",
  email: "",
  phone: "",
  admin: 0
};

export const Dashboard: Function = () => {
  let { push } = useHistory();
  const [user, setUser] = useState(emptyUser);
  const { pathname } = useLocation();

  useEffect(() => {
    me()
      .then((res: User) => {
        setUser(res);
        localStorage.setItem('user', JSON.stringify(res));
      })
      .catch(() => {
        message.error("Failed to load user details. Redirecting to login ...");
        push("/login");
      });
  }, []);
  let [collapsed, setCollapse] = useState(false);
  return (
    <Layout className="full-height">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <Image src={backgroud} />
        </div>
        <Menu mode="inline" defaultSelectedKeys={[pathname]}>
          <Menu.Item key="/dashboard" icon={<DashboardOutlined />} onClick={() => {
            push('/dashboard');
          }}>
            Summary
          </Menu.Item>
          <Menu.Item key="/dashboard/employees" icon={<TeamOutlined />} onClick={() => {
            push('/dashboard/employees');
          }}>
            Employees
          </Menu.Item>
          <Menu.Item key="/dashboard/announcements" icon={<SoundOutlined />} onClick={() => {
            push('/dashboard/announcements');
          }}>
            Announcements
          </Menu.Item>
          <Menu.Item key="/dashboard/events" icon={<CarryOutOutlined />} onClick={() => {
            push('/dashboard/events');
          }}>
            Events
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapse(!collapsed),
            }
          )}
          <div 
          className="userMenuWrapper" 
          style={{
            backgroundImage: "url(" + (user.details && user.details.profile_image?user.details.profile_image : "" )+ ")",
           
          }}
          
          >
          <Menu
            className="top-menu-custom"
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={["0"]}
          >
            <Menu.Item
              key={1}
              onClick={() => {
                localStorage.removeItem("token");
                push("/login");
              }}
            >
              Logout {user.details?user.details.first_name + ' ' +user.details.last_name:user.name}
            </Menu.Item>
          </Menu>
          </div>

        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
          }}
        >
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route exact path="/dashboard">
              <Summery />
            </Route>
            <Route path="/dashboard/employees">
              <Employees />
            </Route>
            <Route path="/dashboard/announcements">
              <Announcements />
            </Route>
            <Route path="/dashboard/events">
              <Events />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};
