import { ForgotPasswordForm } from "../pages/forgotPassword/ForogotPassword";
import { LoginForm } from "../pages/login/Login";
import { RegisterForm } from "../pages/register/Register";
import { Employee } from "../types/types";

export const login: Function = (data: LoginForm) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/create/token', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        },
        body: JSON.stringify({
            email: data.username,
            password: data.password,
            device_name: Date.now().toString()
        })
    }).then(res=>res.json());
}

export const resetPassword: Function = (data: ForgotPasswordForm)=>{
    return fetch(process.env.REACT_APP_API_URL + '/api/reset-password', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        },
    
        body: JSON.stringify(data)
    }).then(res=>res.json());
}

export const resetPasswordConfirm: Function = (data: ForgotPasswordForm)=>{
    return fetch(process.env.REACT_APP_API_URL + '/api/reset-password-confirm', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        },
    
        body: JSON.stringify(data)
    }).then(res=>res.json());
}

export const me: Function = () => {
    return fetch(process.env.REACT_APP_API_URL + '/api/user', {
        mode: "cors",
        cache: 'no-cache', 
        credentials: "include",
        referrerPolicy: "no-referrer",
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem("token")
        })
    }).then(res=>res.json());
}

export const getUser: Function = (id: number) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/employee/'+id.toString(), {
        mode: "cors",
        cache: 'no-cache', 
        credentials: "include",
        referrerPolicy: "no-referrer",
        headers: new Headers({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem("token")
        })
    }).then(res=>res.json());
}


export const signupRequest: Function = (data: ForgotPasswordForm)=>{
    return fetch(process.env.REACT_APP_API_URL + '/api/signup-request', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        },
    
        body: JSON.stringify(data)
    }).then(res=>res.json());
}

export const register: Function = (data: RegisterForm) => {
    data.token = Date.now().toString();
    return fetch(process.env.REACT_APP_API_URL + '/api/register', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(res=>res.json());
}

export const createEmployee: Function = (data: Employee) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/employee', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    
        body: JSON.stringify(data)
    }).then(res=>res.json());
}

export const updateEmployee: Function = (data: Employee, id: number) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/employee-details/'+id.toString(), {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    
        body: JSON.stringify(data)
    }).then(res=>res.json());
}

export const makeAdmin: Function = (id: number, status: string) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/admin/'+id.toString() + '/' + status, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(res=>res.json());
}

export const deleteEmployee: Function = (id: string) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/employee/'+id, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(res=>res.json());
}

export const restoreEmployee: Function = (id: string) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/employee/restore/'+id, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache', 
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }).then(res=>res.json());
}

export const getEmployees: Function = (page: number, all = 0) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/employees/' + page.toString() + '/' + all.toString(), {
        mode: "cors",
      cache: "no-cache",
      credentials: "include",
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    }).then(res=>res.json());
}

export const getAllEmployees: Function = () => {
    return fetch(process.env.REACT_APP_API_URL + '/api/all-employees', {
        mode: "cors",
      cache: "no-cache",
      credentials: "include",
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    }).then(res=>res.json());
}

export const getLatestEmployees: Function = () => {
    return fetch(process.env.REACT_APP_API_URL + '/api/latest-employees', {
        mode: "cors",
      cache: "no-cache",
      credentials: "include",
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    }).then(res=>res.json());
}

export const getCounts: Function = () => {
    return fetch(process.env.REACT_APP_API_URL + '/api/counts', {
        mode: "cors",
      cache: "no-cache",
      credentials: "include",
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    }).then(res=>res.json());
}