import {useEffect, useState} from "react";
import {Col, Form, PageHeader, Radio, Row} from "antd";
import {CheckCircleFilled} from "@ant-design/icons";
import {AnnouncementUserFeedback, FeedbackStatus} from "../../../types/types";

interface FeedbackListProps {

    userList:AnnouncementUserFeedback[];
}
// sort by name
const sortByName = (userList:AnnouncementUserFeedback[])=>{
    return userList.sort((a,b)=>{
        return a.details.first_name.toLowerCase().localeCompare(b.details.first_name.toLowerCase());
    })
}
export const FeedbackList = ({userList}: FeedbackListProps) => {
    const list = sortByName(userList);
    let [modelList, setModelList] = useState([...list]);
    let [modelFilter, setModelFilter] = useState('all');
    const handleFilter = (e: any) => {
        setModelFilter(e.target.value);
        if (e.target.value === 'all') {
            setModelList([...userList]);
        }else if(e.target.value==FeedbackStatus.notSeen){
            setModelList(userList.filter(f => f.feedbck?.status === parseInt(e.target.value, 10 )|| f.feedbck===undefined));
        } else {
            setModelList(userList.filter(f => f.feedbck?.status === parseInt(e.target.value, 10)));
        }

    }
    useEffect(
        () => {
            setModelFilter('all');
            const list = sortByName(userList);
            // reset list once component mount
            setModelList([...list]);
        },
        [userList]
    );
    return (
        <>
            <Row>
                <Col>
                    <PageHeader title="Feedback"/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form layout="inline"
                          className="af-filter">
                        <Form.Item label="">
                            <Radio.Group value={modelFilter} onChange={handleFilter}>
                                <Radio.Button className="af-filter-item" value='all'>All</Radio.Button>
                                <Radio.Button className="af-filter-item" value="2">Acknowledged</Radio.Button>
                                <Radio.Button className="af-filter-item" value="1">Seen</Radio.Button>
                                <Radio.Button className="af-filter-item" value="0">Not seen</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="feedback-info">
                        {
                            modelList.length > 0 ?
                                <ul>
                                    {
                                        modelList.map((f) => {
                                            let statusClassName = 'type-';
                                            switch (f?.feedbck?.status) {
                                                case FeedbackStatus.acknowledge:
                                                    statusClassName += '3';
                                                    break;
                                                case FeedbackStatus.seen:
                                                    statusClassName += '2';
                                                    break;
                                                default:
                                                    statusClassName += '1';
                                            }
                                            return (
                                                <li key={f.id} className={statusClassName}>
                                                    <CheckCircleFilled/> {f.details.first_name  || ""} {f.details.last_name || ""}</li>
                                            )
                                        })

                                    }
                                </ul>
                                : <div className="no-records">No Records Found</div>
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}

