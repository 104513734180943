import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Divider,
  Image,
} from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { login } from "../../services/auth";
import "./style.css";
import backgroud from "../../assets/Softcodeit_Logo-02.png";

export interface LoginForm {
  username: string;
  password: string;
}

export const Login: Function = () => {
  const { push } = useHistory();
  let [loading, setLoading] = useState(false);
  if (loading) {
    return <Spin size="large" className="spin" />;
  }
  return (
    <Row align="middle" className="full-height">
      <Col span={24}>
        <Card className="login-logo">
          <Image src={backgroud} preview={false}/>
          <div className="loginNote">Admin Login of SCIT Team, <br/>If you are not an Admin, You may use our Mobile App</div>
        </Card>
        <Card bordered={false}>
          <Form
            name="basic"
            className="loginForm"
            layout={"vertical"}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={(data: LoginForm) => {
              setLoading(true);
              login(data)
                .then((respnse: any) => {
                  setLoading(false);
                  if (respnse.error) {
                    message.error(respnse.error);
                    return;
                  } else if (respnse.token) {
                    localStorage.setItem("token", respnse.token);
                    message.success(
                      "You login was success. Redirecting to dashboard ..."
                    );
                    push("/dashboard");
                  } else {
                    message.error("Failed to login, please try again ...!");
                  }
                })
                .catch(() => {
                  setLoading(false);
                  message.error("Failed to login, please try again ...!");
                });
            }}
            onFinishFailed={() => {
              message.error("Please fill the correct login details!");
            }}
          >
            <Form.Item
              label="Email"
              name="username"
              hasFeedback
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please input your registered email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              hasFeedback
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 8, message: "Minimum password length is 8!" },
              ]}
            >
              <Input.Password />
            </Form.Item>
            
            <Row>
            <Col span={12}>
            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{span: 16 }}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            </Col>
            <Col span={12} className="forgotpw">
            <Button
                type="link"
                onClick={() => {
                  push("/forgot-password");
                }}
              >
                Forgot Password?
              </Button>
            </Col>

            </Row>
            

            <Form.Item wrapperCol={{ span: 24 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              
            </Form.Item>
          </Form>

          {/*<div className="sign-up-request-container">*/}
          {/*  <Button*/}
          {/*    type="link"*/}
          {/*    onClick={() => {*/}
          {/*      push("/signup-request");*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Send Sign Up Request*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </Card>
      </Col>
    </Row>
  );
};
