import {
    Col,
    PageHeader,
    Row,
    Form,
    Input,
    Button,
    Select,
    message,
    DatePicker,
    Divider,
    Spin, Checkbox,
} from "antd";
import "./style.css";
import {useEffect, useState} from "react";
import {getAllEmployees} from "../../services/auth";
import {Event, EventType, User} from "../../types/types";
import {createEvent, getEventTypes, updateEvent} from "../../services/event";
import moment from "moment";

const emptyUser: User = {
    name: "",
    email: "",
    phone: "",
    admin: 0
};
const emptyEventType: EventType = {
    id: 0,
    name: "",
};

export interface EventFormProps {
    onDone: Function;
    data: Event;
}

export const EventForm = ({onDone, data}: EventFormProps) => {
    console.log('event', data);
    let [employees, setEmployees] = useState([emptyUser]);
    let [eventTypes, setEventTypes] = useState([emptyEventType]);
    let [isLoading, setIsloading] = useState(true);
    let [participantAll, setParticipantAll] = useState(false);
    useEffect(() => {
        getAllEmployees().then((res: User[] | any) => {
            if (res.error) {
                message.error(res.error);
                return;
            }
            setIsloading(false);

            setEmployees(res);
        });
        getEventTypes().then((eventTypes: EventType[]) => {
            setEventTypes(eventTypes);
        });
        setParticipantAll(data.user_all ? data.user_all : false);
    }, []);
    if (isLoading) {
        return <Spin className="spin" size="large"/>;
    }

    function updateParticipantAll(e: any) {
        setParticipantAll(e.target.checked);
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        onBack={() => {
                            onDone();
                        }}
                        title={(data.id && data.id > 0 ? ('Edit Event') : "Add Event")}
                        subTitle={(data.id && data.id > 0 ? "You can edit the Event" : "Please fill out the information below")}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form
                        name="basic"
                        className="form-event"
                        layout={"vertical"}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        initialValues={{remember: true}}
                        onFinish={(value: any) => {
                            console.log('value', value)
                            const eventObj = {
                                user_id: value.organizer,
                                event_type_id: value.type,
                                published_at: value.published_at.format("YYYY-MM-DD HH:mm:ss"),
                                end_at: value.end_at.format("YYYY-MM-DD HH:mm:ss"),
                                name: value.name,
                                description: value.description,
                                users: value.users,
                                user_all: value.user_all ? 1 : 0
                            }
                            if (data.id && data.id > 0) {
                                updateEvent(eventObj, data.id).then((response: any) => {
                                    if (response.error) {
                                        message.error(response.error);
                                        return;
                                    }
                                    setIsloading(true);
                                    message.success("Event Updated!");
                                    onDone();
                                });
                            } else {
                                createEvent(eventObj).then((response: any) => {
                                    if (response.error) {
                                        message.error(response.error);
                                        return;
                                    }
                                    setIsloading(true);
                                    message.success("Event Saved!");
                                    onDone();
                                });
                            }

                        }}
                        onFinishFailed={() => {
                        }}
                    >

                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    name="organizer"
                                    hasFeedback
                                    label="Organizer"
                                    rules={[{required: true}]}
                                    initialValue={data && data.organizer.id ? data.organizer.id : null}
                                >
                                    <Select
                                        placeholder="Host Name"
                                        onChange={() => {
                                        }}
                                        allowClear
                                    >
                                        {employees &&
                                        employees.map((employee: User) => {

                                            return (
                                                <Select.Option
                                                    key={"emp-drop-" + employee.id?.toString()}
                                                    value={employee.id ? employee.id : 0}
                                                >
                                                    {employee?.details?.first_name} {employee?.details?.last_name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    name="type"
                                    hasFeedback
                                    label="Event Type"
                                    rules={[{required: true}]}
                                    initialValue={data && data.event_type_id ? data.event_type_id : null}
                                >
                                    <Select
                                        placeholder="Please Select an event type"
                                        onChange={() => {
                                        }}
                                        allowClear
                                    >
                                        {eventTypes &&
                                        eventTypes.map((eventType: EventType) => {
                                            return (
                                                <Select.Option
                                                    key={"evt-drop-" + eventType.id.toString()}
                                                    value={eventType.id}
                                                >
                                                    {eventType.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Start Date &amp; Time"
                                    hasFeedback
                                    name="published_at"
                                    rules={[{required: true}]}
                                    initialValue={data && data.published_at ? moment(data.published_at) : null}
                                >
                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="End Date &amp; Time"
                                    hasFeedback
                                    name="end_at"
                                    rules={[{required: true}]}
                                    initialValue={data && data.end_at ? moment(data.end_at) : null}
                                >
                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Title"
                                    name="name"
                                    hasFeedback
                                    rules={[{required: true}]}
                                    initialValue={data && data.name ? data.name : null}
                                >
                                    <Input/>
                                </Form.Item>

                                <Row>
                                    <Col span={16}>
                                        <Form.Item
                                            name="users"
                                            hasFeedback
                                            label="Participants"
                                            rules={[{required: !participantAll}]}
                                            initialValue={data && data.users && !participantAll ? data.users.map(u => u.user_id) : null}
                                        >

                                            <Select
                                                showSearch
                                                placeholder="Select Participants"
                                                allowClear
                                                mode="multiple"
                                                optionFilterProp="children"
                                            >
                                                {employees &&
                                                employees.map((employee: User) => {
                                                    return (
                                                        <Select.Option
                                                            key={"emp-drop-" + employee.id?.toString()}
                                                            value={employee.id ? employee.id : 0}
                                                        >
                                                            {employee?.details?.first_name} {employee?.details?.last_name}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="user_all" valuePropName="checked"
                                                   initialValue={data && data.user_all ? data.user_all : false}>
                                            <Checkbox checked={data.user_all ? data.user_all : false}
                                                      onChange={updateParticipantAll}>Select All</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>


                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    hasFeedback
                                    rules={[{required: true}]}
                                    initialValue={data && data.description ? data.description : null}
                                >
                                    <Input.TextArea/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item wrapperCol={{offset: 16, span: 8}}>
                                    <div className="btn-wrapper">
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
